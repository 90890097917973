$edina-cookie-ok: #e6ffc4;

.cookie-modal {
  display: none;
  position: fixed;
  z-index: 2000;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.cookie-modal-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  color: #fff;
  width: 100%;
  background-color: #212529;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  padding-bottom: 2em;
  height: fit-content;

  div {
    margin-left: 2em;
    margin-right: 2em;
  }
}

.cookie-modal-header {
  padding: 2px 16px;
  color: #fff;
  border-bottom: 1px solid #e9ecef;
  margin-left: 2em;
  margin-right: 2em;

  h1 {
    size: 24px;
    font-size: 36px;
  }
}

.cookie-modal-body {
  background-color: #212529;
  padding: 2px 16px;
  margin-left: 2em;
  margin-right: 2em;

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 18px;
    color: #fff;
  }

  a {
    color: #73b8e2;
  }
}

.cookie-modal-footer {
  display: flex;
  padding: 2em 0;
  background-color: #212529;
  color: #fff;
  border-top: 1px solid #e9ecef;
  margin-left: 2em;
  margin-right: 2em;

  div {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
  }
}

.btn {
  background-color: #fff;
  margin-right: 1rem;
  color: #000;
  cursor: pointer;
  line-height: 1.3333333;
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid #fff;
}

.btn:hover {
  color: #fff;
  background-color: #212529;
}

.btn-cookie {
  width: 20em;
  margin-top: 10px;

  &:focus {
    outline: 3px solid #ffa500 !important;
  }
}

.btn-cookie-limited {
  color: #fff;
  background-color: #212529;
}

.btn-cookie-limited:hover {
  color: #000;
  background-color: #fff;
}

.btn-cookie-ok {
  background-color: $edina-cookie-ok;
}

@-webkit-keyframes slideIn {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
