@use '@angular/material' as mat;

$mat-dm-green: (
  50: #ddff94,
  100: #c4ff47,
  200: #b2ff0f,
  300: #87c700,
  400: #72a800,
  500: #5e8a00,
  600: #496b00,
  700: #344d00,
  800: #1f2e00,
  900: #0a0f00,
  A100: #64ff05,
  A200: #53db00,
  A400: #328500,
  A700: #1d4d00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dm-app-primary: mat.define-palette(mat.$deep-purple-palette);
$dm-app-accent: mat.define-palette($mat-dm-green, 500, A100, A400);
// The warn palette is optional (defaults to red).
$dm-app-warn: mat.define-palette(mat.$red-palette);
$dm-app-theme: mat.define-light-theme($dm-app-primary, $dm-app-accent, $dm-app-warn);
@include mat.all-component-themes($dm-app-theme);
@import './scss/modal';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

p {
  // font-family: 'BioRhyme', serif;
  font-family: 'Roboto', sans-serif;
}

body {
  min-height: 100vh;
}

a {
  text-decoration: underline;
}

dm-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top-bar {
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.6);
  margin-bottom: 1rem;

  ul {
    background-color: transparent;
  }
}

.align-right {
  text-align: right;
}

.amount {
  margin: 0.5em;
  font-size: 1.5em;
}

.amount-text {
  color: #b12704;
}

.total-incl-vat {
  font-size: 1.4em;
  color: #b12704;
  font-weight: bold;
}

.other-amount-text {
  color: #b12704;
}

.text--blue {
  color: #005c8a;
}

.text--bold {
  font-weight: bold;
}

.button {
  border-radius: 4px;
  vertical-align: bottom;

  &.success {
    background-color: #7ab900;
    padding: 1rem 3rem;
    color: #000000;

    &:hover {
      color: #fff;
    }
  }
}

// Frr mat-spinner-button, when full width is set also set height
button {
  // scss-lint:disable ClassNameFormat
  &.fullWidth {
    // sass-lint:disable-line class-name-format
    height: 100%;
    font-size: 20px;
  }

  &.mat-raised-button.mat-accent {
    color: #000;
    background-color: #76ad00;

    &:focus {
      outline: 3px solid orange;
    }
  }
}

.mat-button:focus,
.mat-button-base:focus {
  outline: 3px solid orange !important;
}

.button {
  &.alert {
    background-color: #9a3728 !important;
  }

  &.success:hover {
    color: #000;
  }

  &:focus,
  &:hover {
    outline: 3px solid orange;

    &[disabled] {
      outline: none;
    }
  }

  &[disabled] {
    opacity: 1 !important;

    &.success {
      background-color: #99ceb3;
      color: #3a3c39;
    }

    &.secondary {
      background-color: #b7b4b4;
      color: #3a3c39;
    }
  }
}

.boxed {
  border: 2px #cacaca solid;
  border-radius: 10px;
  padding: 1rem;
}

fieldset {
  border: 2px #cacaca solid;
  border-radius: 10px;
  padding: 1rem;
}

fieldset > legend {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

// sass-lint:disable force-element-nesting force-attribute-nesting
input:not(.mat-input-element),
textarea:not(.mat-input-element) {
  &.ng-valid[required] {
    border-left: 5px solid #42a948; // green
  }

  &.ng-invalid {
    border-left: 5px solid #a94442; // red
  }
}

mat-checkbox {
  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
    box-sizing: content-box;
  }

  &.ng-invalid:not(form) .mat-checkbox-inner-container {
    border-left: 5px solid #a94442; // red
  }

  &.ng-valid[required] .mat-checkbox-inner-container {
    border-left: 5px solid #42a948; // green
  }

  // this style allow us to wrap labels of mat-checkbox component
  .mat-checkbox-layout {
    white-space: normal !important;
  }
}

@media print {
  // sass-lint:disable-block no-ids
  body * {
    visibility: hidden;
  }

  #section-to-print {
    position: absolute;
    visibility: visible;
    left: 0;
    top: 0;

    * {
      visibility: visible;
    }
  }
}

.product-image {
  margin: 0 1em 0 0;
  min-width: 4.25em;
  min-height: 4.25em;
  width: 4.25em;
  height: 4.25em;
  padding: 0;
  font-size: 14px;
}

.breakable-data {
  word-break: break-all;
}

_:-ms-fullscreen,
:root footer {
  display: none;
  min-height: 0;
}
